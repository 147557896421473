<template>
  <div>
    <section class="white-container news">

        <!-- категория новости и дата -->
        <div class="news-header">
            <p class="news-header__rubric">{{ getNews.rubric }}</p>
            <p class="news-header__date">{{ getNews.dateTime }}</p>
        </div>

        <!-- заголовок новости -->
        <h5>{{ getNews.title }}</h5>

        <!-- просмотры и комментарии -->
        <div class="news-info">
          <p v-if="getComments.length" class="news-info__comment">{{ getComments.length }}</p>
          <p class="news-info__views">{{ views }}</p>
        </div>

        <!-- превью общей картинки новости  -->
        <!-- <figure v-if="getNews && getNews.previewPhoto" class="news-img"
            :style="`background: url('${require('/assets/temp/' + getNews.previewPhoto)}') center / cover`">
        </figure> -->

        <!-- текст -->
        <p v-if="getContentType === 'RIGHT_PIC' || getContentType === 'LEFT_PIC'" class="news-content__left-img"
          :style=" getImages.length ? `background: url('${'/assets/temp/' + getImages[0]}') center / cover` : ''"
          :class="{'news-content__right-img' : getContentType === 'RIGHT_PIC'}"></p>

        <div v-html="getContentText" class="news-content__text"></div>

        <!-- новостные теги -->
        <div class="news-tags">
            <p class="news-header__rubric" v-for="(tag, key) in getTagList" :key="`tag${key}`">{{ tag }}</p>
        </div>

        <!-- поделиться новостью в соцсетях -->
        <share-social class="news-social"></share-social>

    </section>

    <section v-if="getComments.length" class="white-container comments">
        <p class="title_categories">Комментарии</p>
        <tricolor-line />
        <comment-card v-for="index of showCommentsFull ? getComments.length : 1" :card="getComments[index-1]" :key="`comment${index}`" :listView="false" :showFull="index === 1 ? true : false"/>
        <p class="news-comment__btn" @click="showCommentsFull = !showCommentsFull">
          {{ showCommentsFull ? 'Свернуть комментарии других членов ОП' : `Показать комментарии других членов ОП (${getComments.length - 1}) ` }}
          <i class="news-comment__arrow" :class="{'active' : showCommentsFull}"></i>
        </p>
    </section>

  </div>
</template>

<script>
import ShareSocial from '@/components/news/ShareSocial'
import TricolorLine from '@/components/TricolorLine'
import CommentCard from '@/components/news/CommentCard'

export default {
  name: 'CommentContent',
  data () {
    return {
      views: 86,
      getTempIdNews: 0,
      showCommentsFull: false
    }
  },
  mounted () {
    this.$store.dispatch('getContent', 'News')
  },
  watch: {
    // TEST потом удалить из watch getNews
    getNews (val) {
      this.getTempIdNews = this.getAllNews.findIndex(f => f.id === Number(this.$route.params.id))
    }
  },
  computed: {
    // TEST потом удалить getAllNews
    getAllNews () {
      const content = this.$store.getters.getContent
      return content.content && content.content.news && content.content.news.length ? content.content.news : ''
    },
    getNews () {
      const content = this.$store.getters.getContent
      return content.content && content.content.news && content.content.news.length ? content.content.news[this.getTempIdNews] : ''
    },
    getContentType () {
      const news = this.getNews
      return news.content && news.content.type ? news.content.type : ''
    },
    getContentText () {
      const news = this.getNews
      return news.content && news.content.text ? news.content.text : ''
    },
    getTagList () {
      const news = this.getNews
      return news.tagList ? news.tagList : []
    },
    getComments () {
      const news = this.getNews
      return news.comment ? news.comment : []
    },
    getImages () {
      const news = this.getNews
      return news.content && news.content.image ? news.content.image : []
    }
  },

  components: {
    ShareSocial,
    CommentCard,
    TricolorLine
  }
}
</script>

<style lang="scss" scoped>
.news {

  h5 {
    font-weight: 600;
    font-size: 1.38rem;
    line-height: 1.75rem;
    color: #1F3245;

    margin-bottom: 1.18rem;
  }

  &-header {
    display: flex;
    margin-bottom: 1.18rem;

    &__rubric {
      background: #EEF4FA;
      border-radius: 3px;
      font-weight: 600;
      font-size: 0.8125rem;
      line-height: 123%;
      padding: .375rem .625rem;
      text-transform: uppercase;
      color: #003E78;
      opacity: 0.8;
    }
    &__date {
      font-size: 0.875rem;
      margin-left: 0.625rem;
    }
  }

  &-info {
    display: flex;
    align-items: center;

    &__views,
    &__comment {
      display: flex;
      align-items: center;
      margin-right: 0.75rem;
      margin-bottom: 1.3rem;

      font-weight: 600;
      font-size: 0.8125rem;
      line-height: 125%;

      color: #5A79A8;
    }

    &__views::before{
      background: url(../../assets/icons/view.svg);
    }
    &__comment::before{
      background: url(../../assets/icons/message.svg);
    }
    &__views::before,
    &__comment::before {
      content: '';
      width: 1.3rem;
      height: 1.3rem;
      margin-right: 0.6rem;
      background-size: contain;
      background-repeat: no-repeat;
      background-position: center;
    }
  }

  &-content__left-img {
    width: 15rem;
    height: 11.25rem;
    float: left;
    margin: 0 1.56rem 1.56rem 0;

    border-radius: 0.5rem;
  }

  &-content__right-img {
    float: right;
    margin: 0 0 1.56rem 1.56rem;
  }

  ::v-deep &-content__text{
    margin-bottom: 2.5rem;

    p {
      font-size: 0.875rem;
      margin-bottom: 1.25rem;
      line-height: 157%;
      color: rgba(23, 23, 23, 0.8);
    }
  }

  &-tags {
    display: flex;
    flex-wrap: wrap;

    margin-bottom: 2.5rem;

    p {
      margin: 0.35rem 0.625rem 0.35rem 0;
    }
  }

  &-social {
      border-top: 1px solid #D5D6D9;
      padding-top: 2.5rem;
  }

  &-img {
    width: 100%;
    height: 27.5rem;
    border-radius: 0.5rem;

    margin-bottom: 2.5rem;
  }

  &-comment {

    &__btn {
      font-size: 0.875rem;
      line-height: 157%;
      text-align: center;
      color: #246CB7;
    }

    &__arrow {
      border: solid #5A79A8;
      border-width: 0 .155rem .155rem 0;
      border-radius: .0625rem;
      display: inline-block;
      padding: .1875rem;
      transform: rotate(45deg);
      -webkit-transform: rotate(45deg);
      margin-bottom: .0625rem;
      margin-left: .0625rem;
    }

    &__arrow.active {
      transform: rotate(-135deg);
      -webkit-transform: rotate(-135deg);
      margin: 0;
    }
  }

}

.comments {
  margin-top: 3.125rem;

  .line_wrapper {
    padding: 1.375rem 0;
  }

}
  @media screen and (max-width: 768px){
    .news {

      &-img {
        width: 100%;
        height: 21.81rem;
      }

    }
  }
   @media screen and (max-width: 420px){
    .news {
      &-header {
        flex-wrap: wrap;
      }

      &-content__left-img {
        width: 100%;
        height: 11.25rem;
        display: block;
        margin: 0 1.56rem 1.56rem 0;
      }

      &-content__right-img {
        width: 100%;
        height: 11.25rem;
        display: block;
        margin: 0 0 1.56rem 1.56rem;
      }

      &-img {
        width: 100%;
        height: 12.5rem;
      }

      &-social {
        padding-top: 1.375rem;
      }

    }
  }
</style>
