<template>
    <section>
        <router-link class="come-back" to="/news?tab=Comment">
          <div></div>
          {{$store.getters.GET_LANG ? 'Вернуться в раздел “Все комментарии”' : 'Go back to the section “All comments”'}}
        </router-link>

        <div class="two-columns-wrapper">
          <main>
            <comment-content />
          </main>

          <aside class="white-container">
            {{$store.getters.GET_LANG ? 'блок рекомендаций' : 'recommendation block'}}
          </aside>
        </div>

    </section>
</template>
<script>
import CommentContent from '@/components/news/CommentContent'

export default {
  name: 'Comments',
  components: {
    'comment-content': CommentContent
  }
}
</script>
<style lang="scss" scoped>
@media screen and (max-width: 768px) {
  .come-back {
    width: 90%;
  }
}
</style>
